var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[(!_vm.prazoIndisponivel)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-progress-linear',_vm._g(_vm._b({style:(_vm.isInLabGuarulhos
            ? _vm.diasPassados === _vm.prazoTotalEtapaLabGuarulhos
              ? 'width: 30%;  border-radius: 5px 0px 0px 5px; border: 1pt solid #fb8c00;cursor:pointer;background-color:#fb8c00;'
              : _vm.percentualAtrasado !== 100
              ? 'width: 30%;  border-radius: 5px 0px 0px 5px;  border: 1pt solid #df323b;cursor:pointer;background-color:#df323b'
              : 'width: 30%;  border-radius: 5px 0px 0px 5px;  border: 1pt solid #c0c0c0;cursor:pointer;background-color:#c0c0c0'
            : _vm.isCatalisador && !_vm.possuiEnsaioICP
            ? _vm.horasPassadas === _vm.prazoTotal
              ? 'width:100% ; border-radius:5px;  border: 1pt solid #fb8c00;cursor:pointer;background-color:#fb8c00'
              : _vm.horasPassadas > _vm.prazoTotal
              ? 'width:100% ; border-radius:5px;  border: 1pt solid #df323b;cursor:pointer;background-color:#df323b'
              : 'width:100% ; border-radius:5px;  border: 1pt solid #c0c0c0;cursor:pointer;background-color:#c0c0c0'
            : _vm.diasPassados === _vm.prazoTotal
            ? 'width:100% ; border-radius:5px;  border: 1pt solid #fb8c00;cursor:pointer;background-color:#fb8c00'
            : _vm.diasPassados > _vm.prazoTotal
            ? 'width:100% ; border-radius:5px;  border: 1pt solid #df323b;cursor:pointer;background-color:#df323b'
            : 'width:100% ; border-radius:5px;  border: 1pt solid #c0c0c0;cursor:pointer;background-color:#c0c0c0'),attrs:{"value":_vm.percentual,"height":_vm.height,"color":_vm.color,"striped":_vm.amostraFinalizada,"background-color":"grey lighten-2"},on:{"click":_vm.openInfoPrazo}},'v-progress-linear',attrs,false),on),[_c('AmostrasDialogPrazo',{attrs:{"amostra":_vm.amostra,"dataPrazo":_vm.dataPrazoTotal,"dataInicio":_vm.inicioPrazo,"horasPassadas":_vm.horasPassadas,"diasPassados":_vm.diasPassados,"possuiEnsaioICP":_vm.possuiEnsaioICP},on:{"cancelar":_vm.onCancelar},model:{value:(_vm.dialogPrazo),callback:function ($$v) {_vm.dialogPrazo=$$v},expression:"dialogPrazo"}}),_c('div',{class:_vm.textClass,staticStyle:{"color":"rgba(0, 0, 0, 0.85)"}},[_vm._v(" "+_vm._s(_vm.isCatalisador && !_vm.possuiEnsaioICP ? _vm.horasPassadas : _vm.diasPassados)+"/"+_vm._s(_vm.isInLabGuarulhos ? _vm.prazoTotalEtapaLabGuarulhos : _vm.prazoTotal)+_vm._s(_vm.isCatalisador && !_vm.possuiEnsaioICP ? 'h' : '')+" ")])],1),(_vm.isInLabGuarulhos)?_c('v-progress-linear',_vm._g(_vm._b({style:(_vm.percentualAtrasado != 100
            ? 'width: 70%; border-radius: 0px 5px 5px 0px; border: 1pt solid #df323b;background-color:#df323b;cursor:pointer'
            : 'width: 70%;border-radius: 0px 5px 5px 0px; border: 1pt solid #c0c0c0;background-color:#c0c0c0;cursor:pointer'),attrs:{"striped":"","reverse":"","value":_vm.percentualAtrasado,"height":_vm.height,"background-color":_vm.color,"color":"grey "},on:{"click":_vm.openInfoPrazo}},'v-progress-linear',attrs,false),on)):_vm._e()]}}],null,false,3670635137)},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-1 font-weight-medium"},[_vm._v("Data de início:")]),_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.inicioPrazo)))])]),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-1 font-weight-medium"},[_vm._v("Prazo:")]),(!_vm.isCatalisador || _vm.possuiEnsaioICP)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.dataPrazoTotal))+" ("+_vm._s(_vm.prazoTotal)+" dias úteis) ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.dataPrazoTotal))+" ("+_vm._s(_vm.prazoTotal)+" horas) ")])])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-progress-linear',_vm._g(_vm._b({staticStyle:{"'width":"70%","border-radius":"5px 5px 5px 5px","border":"1pt solid #c0c0c0","background-color":"#c0c0c0"},attrs:{"striped":"","reverse":"","value":_vm.percentualAtrasado,"height":_vm.height,"color":"grey"}},'v-progress-linear',attrs,false),on))]}}])},[_c('span',[_vm._v("Prazo indisponível")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }