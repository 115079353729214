var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"width":"900","fullscreen":_vm.$vuetify.breakpoint.xsOnly},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-card',{attrs:{"tile":_vm.$vuetify.breakpoint.xsOnly}},[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-information")]),_c('span',[_vm._v("Laudo do pedido")])],1),_c('v-icon',{on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("mdi-close")])],1)]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-2"},[_c('span',[_vm._v("Selecione as amostras:")]),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items":_vm.amostrasItems,"headers":_vm.headers,"item-class":_vm.itemClass,"hide-default-footer":"","disable-pagination":"","disable-sort":"","no-data-text":"Nenhuma amostra associada ao pedido","dense":""},scopedSlots:_vm._u([{key:"item.etapa",fn:function(ref){
var item = ref.item;
return [_c('AmostrasLogsCustomLabel',{attrs:{"etapa":item.etapa}})]}},{key:"item.selecionada",fn:function(ref){
var item = ref.item;
return [(!item.permitido)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{staticClass:"my-0 ml-3",attrs:{"hide-details":"","disabled":!item.permitido},model:{value:(item.selecionada),callback:function ($$v) {_vm.$set(item, "selecionada", $$v)},expression:"item.selecionada"}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.tooltipText)}})]):_c('v-switch',{staticClass:"my-0 ml-3",attrs:{"hide-details":"","disabled":!item.permitido},model:{value:(item.selecionada),callback:function ($$v) {_vm.$set(item, "selecionada", $$v)},expression:"item.selecionada"}})]}}],null,true)})],1),_c('div',{staticClass:"mt-4 d-flex justify-space-between"},[_c('v-btn',{staticClass:"mb-3 mb-sm-0",attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_c('span',[_vm._v("Cancelar")])],1),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading.laudo,"disabled":_vm.amostrasIds.length === 0},on:{"click":function($event){return _vm.geraLaudo()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_c('span',[_vm._v("Gerar laudo")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }