var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-pagination":"","dense":"","items":_vm.laudosMostrados,"item-class":_vm.itemClassText,"headers":_vm.headers,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.createdAt))+" ")]}},{key:"item.observacoes",fn:function(ref){
var item = ref.item;
return [(item.observacoes)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-tooltip-text")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.observacoes)+" ")])]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.gerarPdf",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"target":"_blank","color":item.index === _vm.laudosWithIndex.length
              ? 'deep-orange darken-1'
              : 'deep-orange lighten-3',"href":_vm.hrefGerarPdfLaudo(item.id),"icon":""}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }