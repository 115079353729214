var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.amostraId)?_c('AmostrasDialogInfo',{attrs:{"amostraId":_vm.amostraId},on:{"amostra-removida":_vm.onAmostraRemovida,"amostra-alterada":_vm.onAmostraAlterada},model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-sm-center"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"placeholder":"Busca","append-icon":"mdi-magnify","clearable":"","filled":"","rounded":"","dense":"","hide-details":""},on:{"focus":function($event){_vm.listenerDisabled = true},"blur":function($event){_vm.listenerDisabled = false}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.permissaoEnviarLaboratorio && !_vm.modoEnvio)?_c('v-btn',{staticClass:"mb-2 ml-sm-2",attrs:{"color":"primary"},on:{"click":_vm.iniciarEnvio}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-send")]),_c('span',[_vm._v("Enviar amostras")])],1):_vm._e(),_c('v-expand-x-transition',[(_vm.modoEnvio)?_c('BarCodeReaderListenerAlert',{staticClass:"ml-2 mt-2",attrs:{"listenerDisabled":_vm.dialogInfo || _vm.tab !== 2 || !_vm.modoEnvio || _vm.listenerDisabled,"textOnFocus":"Aguardando leitura"},on:{"code-scanned":function($event){return _vm.onCodeScanned($event)}}}):_vm._e()],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.amostrasMapped,"item-class":_vm.itemClassColor,"headers":_vm.headers,"loading":_vm.loading,"show-select":_vm.modoEnvio,"sort-by":"urgente","sort-desc":"","disable-pagination":"","hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.prefixoIdentificacao",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openDialogInfo(item)}}},[_c('CustomText',{attrs:{"content":item.prefixoIdentificacao}})],1)]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.descricao}})]}},{key:"item.pesoAmostraKg",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.pesoAmostraKg,"sufixo":" kg"}})]}},{key:"item.amostraLogAtualCreatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.amostraLogAtualCreatedAt))+" ")]}},{key:"item.usuarioNomeCompleto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.usuarioNomeCompleto)+" ")]}},{key:"item.solicitante",fn:function(ref){
var item = ref.item;
return [(_vm.amostraSolicitante(item))?_c('span',[_vm._v(" "+_vm._s(_vm.amostraSolicitante(item))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.urgente",fn:function(ref){
var item = ref.item;
return [(item.urgente)?_c('span',{staticClass:"error--text text--darken-4 font-weight-bold"},[_vm._v(" Sim ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("Não")])]}},{key:"item.ensaio",fn:function(ref){
var item = ref.item;
return [(item.tipo === _vm.tipoAmostra.OUTROS)?_c('AmostrasInfoOutros',{attrs:{"amostra":item,"short":""}}):(item.tipo === _vm.tipoAmostra.PLACA)?_c('AmostrasInfoCalcinacao',{attrs:{"amostra":item,"short":""}}):_c('AmostrasInfoUmidade',{attrs:{"amostra":item,"short":""}})]}},{key:"item.prazo",fn:function(ref){
var item = ref.item;
return [_c('AmostrasProgressBarPrazo',{attrs:{"amostra":item,"dense":""}})]}},{key:"item.detalhes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info","disabled":!_vm.permissaoBuscar},on:{"click":function($event){return _vm.openDialogInfo(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true),model:{value:(_vm.amostrasSelecionadas),callback:function ($$v) {_vm.amostrasSelecionadas=$$v},expression:"amostrasSelecionadas"}})],1),(_vm.modoEnvio)?_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-space-between mt-3"},[_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.amostrasSelecionadas = [];
        _vm.modoEnvio = false;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_c('span',[_vm._v("Cancelar")])],1),_c('div',{staticClass:"d-flex flex-column flex-sm-row align-center"},[_c('div',{staticClass:"text-body-2 text--secondary my-3 my-sm-0 mr-3"},[_vm._v(" "+_vm._s(_vm.amostrasSelecionadas.length)+" amostra(s) selecionada(s) ")]),_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"color":"success","disabled":_vm.amostrasSelecionadas.length === 0},on:{"click":function($event){return _vm.confirmarEnvioDebounce()}}},[_c('v-icon',[_vm._v("mdi-check")]),_c('span',[_vm._v("Confirmar envio")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }