var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.amostra)?_c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-identifier")]),_c('span',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v(" Informações gerais ")])],1),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","depressed":"","small":""},on:{"click":function($event){return _vm.imprimeEtiqueta()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-printer-pos")]),_c('span',[_vm._v("Etiqueta")])],1)],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-row',[_c('CustomVColInfo',{attrs:{"title":"Identificação","sm":"3"}},[_vm._v(" "+_vm._s(_vm.amostra.prefixo)+" "+_vm._s(_vm.amostra.identificacao)+" ")]),_c('CustomVColInfo',{attrs:{"title":"Tipo","sm":"3"}},[_vm._v(" "+_vm._s(_vm.$t(("TipoAmostraEnum." + (_vm.amostra.tipo))))+" ")]),_c('CustomVColInfo',{attrs:{"title":"Urgente","sm":"6"}},[(_vm.amostra.urgente)?_c('span',{staticClass:"error--text font-weight-medium"},[_vm._v(" Sim ")]):_c('span',[_vm._v("Não")])]),(
        _vm.amostra.amostraExportacao.cliente !==
        _vm.ClientesAmostraExportacaoEnum.OUTROS
      )?_c('CustomVColInfo',{attrs:{"title":"Cliente","sm":"3"}},[_vm._v(" "+_vm._s(_vm.amostra.amostraExportacao.cliente)+" ")]):_c('CustomVColInfo',{attrs:{"title":"Cliente","sm":"3"}},[_vm._v(" "+_vm._s(_vm.amostra.amostraExportacao.cliente)+" - "+_vm._s(_vm.amostra.amostraExportacao.clienteOutros)+" ")]),_c('CustomVColInfo',{attrs:{"title":"Referência cliente","sm":"3"}},[_vm._v(" "+_vm._s(_vm.amostra.amostraExportacao.clienteRef)+" ")]),_c('CustomVColInfo',{attrs:{"title":"Referência Lorene","sm":"6"}},[_vm._v(" "+_vm._s(_vm.amostra.amostraExportacao.loreneRef)+" ")]),_c('CustomVColInfo',{attrs:{"title":"Código container","sm":"3"}},[_vm._v(" "+_vm._s(_vm.amostra.amostraExportacao.container)+" ")]),_c('CustomVColInfo',{attrs:{"title":"Peso container","sm":"3"}},[_vm._v(" "+_vm._s(_vm.amostra.amostraExportacao.pesoContainerKg)+"kg ")]),_c('CustomVColInfo',{attrs:{"title":"Local","sm":"3"}},[_vm._v(" "+_vm._s(_vm.amostra.amostraExportacao.local)+" ")]),(
        _vm.amostra.amostraExportacao.cliente ===
          _vm.ClientesAmostraExportacaoEnum.AURUBIS ||
        _vm.amostra.amostraExportacao.cliente ===
          _vm.ClientesAmostraExportacaoEnum.OUTROS
      )?_c('CustomVColInfo',{attrs:{"title":"Fator de correção","sm":"3","condition":!!_vm.amostra.amostraExportacao.fatorCorrecao}},[_vm._v(" "+_vm._s(_vm.amostra.amostraExportacao.fatorCorrecao)+" ")]):_vm._e(),_c('CustomVColInfo',{attrs:{"title":"Observações","sm":"12","condition":!!_vm.amostra.observacoes}},[_vm._v(" "+_vm._s(_vm.amostra.observacoes)+" ")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }