var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.ensaioAmostra && _vm.amostraResultadoId)?_c('EnsaiosDialogRegistrarResultados',{attrs:{"ensaioAtual":_vm.ensaioAmostra,"amostraId":_vm.amostraResultadoId,"balancas":_vm.balancasConectadas},on:{"cancelar":_vm.onCancelar,"resultado-registrado":_vm.onResultadoRegistrado},model:{value:(_vm.dialogRegistrarResultados),callback:function ($$v) {_vm.dialogRegistrarResultados=$$v},expression:"dialogRegistrarResultados"}}):_vm._e(),(_vm.dialogInfo)?_c('AmostrasDialogInfo',{ref:"amostrasDialogInfo",attrs:{"amostraId":_vm.amostraInfoId},on:{"amostra-removida":_vm.onAmostraRemovida,"amostra-alterada":_vm.onAmostraAlterada},model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}}):_vm._e(),_c('EnsaiosDialogSolicitacao',{attrs:{"amostraId":_vm.amostraSolicitacaoId,"laboratorioGuarulhos":""},model:{value:(_vm.dialogSolicitacaoEnsaio),callback:function ($$v) {_vm.dialogSolicitacaoEnsaio=$$v},expression:"dialogSolicitacaoEnsaio"}}),_c('div',{staticClass:"d-flex align-center mb-2"},[_c('v-text-field',{ref:"searchField",attrs:{"placeholder":"Busca","append-icon":"mdi-magnify","clearable":"","filled":"","rounded":"","dense":"","hide-details":""},on:{"focus":function($event){_vm.listenerDisabled = true},"blur":function($event){_vm.listenerDisabled = false}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('BalancasDiponiveis',{staticClass:"ml-2 mr-1",attrs:{"balancas":_vm.balancas}}),_c('BarCodeReaderListenerAlert',{attrs:{"listenerDisabled":_vm.dialogInfo ||
        _vm.dialogRegistrarResultados ||
        _vm.tab !== 1 ||
        _vm.listenerDisabled,"textOnFocus":"Aguardando leitura"},on:{"code-scanned":function($event){return _vm.onCodeScanned($event)}}})],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.amostrasMapped,"item-class":_vm.itemClassColor,"headers":_vm.headers,"loading":_vm.loading,"sort-by":"urgente","sort-desc":"","disable-pagination":"","hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.prefixoIdentificacao",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openDialogInfo(item.id)}}},[_c('CustomText',{attrs:{"content":item.prefixoIdentificacao}})],1)]}},{key:"item.descricao",fn:function(ref){
        var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.descricao}})]}},{key:"item.pesoAmostraKg",fn:function(ref){
        var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.pesoAmostraKg,3, 0),"sufixo":" kg"}})]}},{key:"item.amostraLogAtualCreatedAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.amostraLogAtualCreatedAt))+" ")]}},{key:"item.usuarioNomeCompleto",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.usuarioNomeCompleto)+" ")]}},{key:"item.solicitante",fn:function(ref){
        var item = ref.item;
return [(_vm.amostraSolicitante(item))?_c('span',[_vm._v(" "+_vm._s(_vm.amostraSolicitante(item))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.urgente",fn:function(ref){
        var item = ref.item;
return [(item.urgente)?_c('span',{staticClass:"error--text text--darken-3 font-weight-bold"},[_vm._v(" Sim ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("Não")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(_vm.verificaEnsaio(item) === _vm.TipoEnsaioMetodoEnum.CALCINACAO)?[_c('AmostrasStatusCalcinacao',{attrs:{"statusCalcinacao":_vm.getStatusEnsaio(item)}})]:_vm._e(),(_vm.verificaEnsaio(item) === _vm.TipoEnsaioMetodoEnum.UMIDADE_ESTUFA)?[_c('AmostrasStatusUmidade',{attrs:{"statusUmidade":_vm.getStatusEnsaio(item)}})]:_vm._e(),(!_vm.verificaEnsaio(item))?[_c('AmostrasStatusOutros',{attrs:{"amostra":item}})]:_vm._e()]}},{key:"item.prazo",fn:function(ref){
        var item = ref.item;
return [_c('AmostrasProgressBarPrazo',{attrs:{"amostra":item,"dense":""}})]}},{key:"item.ensaiobtn",fn:function(ref){
        var item = ref.item;
return [(_vm.verificaEnsaio(item) === _vm.TipoEnsaioMetodoEnum.CALCINACAO)?[_c('v-btn',{attrs:{"icon":"","color":"deep-orange"},on:{"click":function($event){return _vm.openDialogRegistrarResultados(item)}}},[_c('v-icon',[_vm._v("mdi-fire")])],1)]:_vm._e(),(_vm.verificaEnsaio(item) === _vm.TipoEnsaioMetodoEnum.UMIDADE_ESTUFA)?[_c('v-btn',{attrs:{"icon":"","color":"cyan"},on:{"click":function($event){return _vm.openDialogRegistrarResultados(item)}}},[_c('v-icon',[_vm._v("mdi-water")])],1)]:_vm._e(),(!_vm.verificaEnsaio(item))?[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openDialogSolicitacaoEnsaio(item.id)}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]:_vm._e()]}},{key:"item.detalhes",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info","disabled":!_vm.permissaoBuscar},on:{"click":function($event){return _vm.openDialogInfo(item.id)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true),model:{value:(_vm.amostrasSelecionadas),callback:function ($$v) {_vm.amostrasSelecionadas=$$v},expression:"amostrasSelecionadas"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }