var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!!_vm.ensaioAliquotaSelecionado && _vm.dialogHistorico)?_c('EnsaiosAliquotasDialogHistoricoResultados',{attrs:{"amostra":_vm.amostra,"ensaioAtual":_vm.ensaio,"ensaioAliquota":_vm.ensaioAliquotaSelecionado,"tipoEnsaio":_vm.tipoEnsaio,"nomeEnsaio":_vm.ensaio.ensaioMetodoNome,"permiteAlterar":_vm.permiteAlterar,"showActionsEnsaio":_vm.showActionsEnsaio},on:{"cancelar":_vm.onCancelar,"resultado-alterado":_vm.onResultadoAlterado},model:{value:(_vm.dialogHistorico),callback:function ($$v) {_vm.dialogHistorico=$$v},expression:"dialogHistorico"}}):_vm._e(),_c('v-card',{attrs:{"tile":"","outlined":""}},[(_vm.aliquotasComResultadosRegistrados.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.aliquotasComResultadosRegistrados,"disable-pagination":"","disable-sort":"","hide-default-footer":"","mobile-breakpoint":"0","dense":""},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [(_vm.amostra)?[(item.nome)?_c('CustomText',{attrs:{"content":item.categoria
                ? ("" + (_vm.amostra.prefixo) + (_vm.amostra.identificacao) + "-" + (item.nome) + " (" + (_vm.$t(("CategoriaEnsaioAliquotaEnum." + (item.categoria)))) + ")")
                : ("" + (_vm.amostra.prefixo) + (_vm.amostra.identificacao) + "-" + (item.nome))}}):_c('CustomText',{attrs:{"content":("" + (_vm.amostra.prefixo) + (_vm.amostra.identificacao))}})]:_vm._e()]}},{key:"item.pesoInicialG",fn:function(ref){
                var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.logResultadosAtualResultados.pesoInicialG,4, 0),"sufixo":"g"}})]}},{key:"item.pesoFinalG",fn:function(ref){
                var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.logResultadosAtualResultados.pesoFinalG,4, 0),"sufixo":"g"}})]}},{key:"item.porcentagemUmidade",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPercent")(item.logResultadosAtualResultados.porcentagemUmidade,2, 0))+" ")]}},{key:"item.porcentagemCarbono",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPercent")(item.logResultadosAtualResultados.porcentagemCarbono,2, 0))+" ")]}},{key:"item.aprovado",fn:function(ref){
                var item = ref.item;
return [(item.logResultadosAtualResultados.aprovado)?_c('span',{staticClass:"success--text text--darken-3 font-weight-bold"},[_vm._v(" Sim ")]):_c('span',{staticClass:"error--text text--darken-3 text--disabled"},[_vm._v(" Não ")])]}},{key:"item.massaAmostraG",fn:function(ref){
                var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.logResultadosAtualResultados.massaAmostraG,4, 0),"sufixo":"g"}})]}},{key:"item.massaPerolaMg",fn:function(ref){
                var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.logResultadosAtualResultados.massaPerolaMg,4, 0),"sufixo":"mg"}})]}},{key:"item.diluicaoMl",fn:function(ref){
                var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.logResultadosAtualResultados.diluicaoMl,0, 0),"sufixo":"ml"}})]}},{key:"item.logResultadosAtualCreatedAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.logResultadosAtualCreatedAt))+" ")]}},{key:"item.arquivo",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!item.logResultadosAtualArquivoId,"icon":"","href":_vm.pathArquivoLogResultado(item),"target":"_blank","color":"primary"}},[_c('v-icon',[_vm._v("mdi-file-download")])],1)]}},{key:"item.logResultadosAtualObservacoes",fn:function(ref){
                var item = ref.item;
return [(item.logResultadosAtualObservacoes)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var attrs = ref.attrs;
                var on = ref.on;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-tooltip-text")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.logResultadosAtualObservacoes)+" ")])]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.historico",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogHistorico(item.id)}}},[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }