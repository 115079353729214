var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-sm-center mt-4"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"placeholder":"Busca","append-icon":"mdi-magnify","clearable":"","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"no-data-text":"Não há amostras aguardando análise de ICP no laboratório","loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.ensaioAliquotas,"item-class":_vm.itemClass,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.identificacaoAliquota",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.identificacaoAliquota)+" ")]}},{key:"item.identificacaoAmostra",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.identificacaoAmostra)+" ")])]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [(item.nome)?_c('span',[_vm._v(" "+_vm._s(item.nome)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.tipoAliquota",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipoAliquota)+" ")]}},{key:"item.massaAmostraG",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('CustomText',{attrs:{"content":_vm.resultadoMassaAmostraG(item),"sufixo":"g"}})],1)]}},{key:"item.massaPerolaMg",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('CustomText',{attrs:{"content":_vm.resultadoMassaPerolaMg(item),"sufixo":"mg"}})],1)]}},{key:"item.diluicaoMl",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('CustomText',{attrs:{"content":_vm.resultadoDiluicaoMl(item),"sufixo":"ml"}})],1)]}},{key:"item.sampleId",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-autocomplete',{attrs:{"placeholder":"Selecione...","items":_vm.itemsSampleId,"outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(item._sampleId),callback:function ($$v) {_vm.$set(item, "_sampleId", $$v)},expression:"item._sampleId"}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }