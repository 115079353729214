var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-pagination":"","dense":"","items":_vm.arquivos,"headers":[
      { text: 'Nome', value: 'originalname', class: 'text-no-wrap' },
      {
        text: 'Tamanho',
        value: 'size',
        class: 'text-no-wrap',
        width: 0,
      },
      {
        text: 'Abrir',
        value: 'ver',
        filterable: false,
        sortable: false,
        align: 'center',
        width: 1,
      },
      {
        text: 'Opções',
        value: 'opcoes',
        filterable: false,
        sortable: false,
        align: 'center',
        width: 1,
      } ]},scopedSlots:_vm._u([{key:"item.size",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.humanFileSize(item.size, true))+" ")]}},{key:"item.ver",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"href":_vm.pathArquivo(item.id),"target":"_blank","color":"primary","icon":""}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1)]}},{key:"item.opcoes",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"right":"","offset-x":"","disabled":!_vm.permissaoRemoverAnexo},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.permissaoRemoverAnexo ? '' : 'grey lighten-1'}},on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.removeAnexo(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Remover anexo")])],1)],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }