var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dialogBarCode)?_c('BarCodeReaderDialog',{attrs:{"rules":_vm.rulesBarCode},on:{"barcode-lido":function($event){return _vm.selecionaAmostra($event)}},model:{value:(_vm.dialogBarCode),callback:function ($$v) {_vm.dialogBarCode=$$v},expression:"dialogBarCode"}}):_vm._e(),(_vm.amostraId)?_c('AmostrasDialogInfo',{attrs:{"amostraId":_vm.amostraId},on:{"amostra-removida":_vm.onAmostraRemovida,"amostra-alterada":_vm.onAmostraAlterada},model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}}):_vm._e(),(_vm.dialogRecebimento && _vm.amostraId)?_c('AmostrasDialogRecebimentoLaboratorioGuarulhos',{attrs:{"amostraId":_vm.amostraId},on:{"cancelar":_vm.onCancelar,"amostra-recebida":_vm.onAmostraRecebida},model:{value:(_vm.dialogRecebimento),callback:function ($$v) {_vm.dialogRecebimento=$$v},expression:"dialogRecebimento"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-sm-center"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"placeholder":"Busca","append-icon":"mdi-magnify","clearable":"","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.amostrasMapped,"item-class":_vm.itemClassColor,"headers":_vm.headers,"loading":_vm.loading,"sort-by":"urgente","sort-desc":"","disable-pagination":"","hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.prefixoIdentificacao",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openDialogInfo(item)}}},[_c('CustomText',{attrs:{"content":item.prefixoIdentificacao}})],1)]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.descricao}})]}},{key:"item.pesoAmostraKg",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.pesoAmostraKg,"sufixo":" kg"}})]}},{key:"item.amostraLogAtualCreatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.amostraLogAtualCreatedAt))+" ")]}},{key:"item.usuarioNomeCompleto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.usuarioNomeCompleto)+" ")]}},{key:"item.solicitante",fn:function(ref){
var item = ref.item;
return [(_vm.amostraSolicitante(item))?_c('span',[_vm._v(" "+_vm._s(_vm.amostraSolicitante(item))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.urgente",fn:function(ref){
var item = ref.item;
return [(item.urgente)?_c('span',{staticClass:"error--text text--darken-4 font-weight-bold"},[_vm._v(" Sim ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("Não")])]}},{key:"item.btnReceber",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.permissaoReceberLaboratorioGuarulhos,"icon":""},on:{"click":function($event){return _vm.selecionaAmostra(item.identificacao)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-inbox-arrow-down")])],1)]}},{key:"item.detalhes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info","disabled":!_vm.permissaoBuscar},on:{"click":function($event){return _vm.openDialogInfo(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }