var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"no-data-text":"Nenhuma alíquota foi associada a um Sample ID","headers":_vm.headers,"items":_vm.ensaioAliquotasComConcentracoes,"item-class":_vm.itemClass,"expanded":_vm.expanded,"show-expand":"","single-expand":"","disable-pagination":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.identificacaoAliquota",fn:function(ref){
var item = ref.item;
return [(item.nome)?_c('div',[_c('CustomText',{attrs:{"content":item.categoria
                ? ("" + (item.ensaioAtual.amostraAtual.prefixo) + (item.ensaioAtual.amostraAtual.identificacao) + "-" + (item.nome) + " (" + (_vm.$t(
                    ("CategoriaEnsaioAliquotaEnum." + (item.categoria))
                  )) + ")")
                : ("" + (item.ensaioAtual.amostraAtual.prefixo) + (item.ensaioAtual.amostraAtual.identificacao) + "-" + (item.nome))}})],1):_c('div',[_c('CustomText',{attrs:{"content":("" + (item.ensaioAtual.amostraAtual.prefixo) + (item.ensaioAtual.amostraAtual.identificacao))}})],1)]}},{key:"item.identificacaoAmostra",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('CustomText',{attrs:{"content":("" + (item.ensaioAtual.amostraAtual.prefixo) + (item.ensaioAtual.amostraAtual.identificacao))}})],1)]}},{key:"item.nome",fn:function(ref){
                var item = ref.item;
return [(item.nome)?_c('span',[_c('CustomText',{attrs:{"content":item.nome}})],1):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.massaAmostraG",fn:function(ref){
                var item = ref.item;
return [_c('span',[_c('CustomText',{attrs:{"content":item.logResultadosAtualResultados.massaAmostraG,"sufixo":"g"}})],1)]}},{key:"item.massaPerolaMg",fn:function(ref){
                var item = ref.item;
return [_c('span',[_c('CustomText',{attrs:{"content":item.logResultadosAtualResultados.massaPerolaMg,"sufixo":"mg"}})],1)]}},{key:"item.diluicaoMl",fn:function(ref){
                var item = ref.item;
return [_c('span',[_c('CustomText',{attrs:{"content":item.logResultadosAtualResultados.diluicaoMl,"sufixo":"ml"}})],1)]}},{key:"item._sampleId",fn:function(ref){
                var item = ref.item;
return [_c('span',[_c('CustomText',{attrs:{"content":item._sampleId}})],1)]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticClass:"pa-0 ma-0",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"grey lighten-3 pb-3",attrs:{"tile":"","flat":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headersResultados,"items":item.concentracoes,"item-class":_vm.itemClassConcentracoes,"sort-by":"Elem","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.itemSelecionado",fn:function(ref){
                var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":""},model:{value:(item._selecionado),callback:function ($$v) {_vm.$set(item, "_selecionado", $$v)},expression:"item._selecionado"}})]}}],null,true)})],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }