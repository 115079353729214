var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Elemento")]),_vm._l((_vm.ensaioAliquotas),function(aliquota){return _c('th',{key:aliquota.id,class:aliquota._selecionada ? '' : 'text--disabled'},[_vm._v(" "+_vm._s(aliquota.identificacao)+" (mg/L) ")])}),_c('th',[_vm._v("Média (mg/L)")]),_c('th',[_vm._v("Resultado (ppm)")])],2)]),_c('tbody',[(_vm.elementos.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"5","align":"center"}},[_c('span',{staticClass:"text--disabled"},[_vm._v(" Nenhuma concentração associada a análise. ")])])]):_vm._e(),_vm._l((_vm.elementos),function(elemento){return _c('tr',{key:elemento},[_c('td',[_vm._v(" "+_vm._s(elemento)+" ")]),_vm._l((_vm.ensaioAliquotas),function(aliquota){return _c('td',{key:aliquota.id,class:[
              aliquota._selecionada ? '' : 'text--disabled',
              _vm.classTdElemento(elemento, aliquota) ]},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.changeElementoSelecionado(elemento, aliquota)}}},[_c('span',{class:[
                    _vm.classSpanElemento(elemento, aliquota),
                    hover ? 'font-weight-medium' : '' ]},[_vm._v(" "+_vm._s(_vm._f("formatDecimal")(_vm.getConcentracaoElemento(elemento, aliquota),3, 0))+" ")]),(hover)?_c('v-icon',{staticClass:"ml-1",staticStyle:{"text-decoration":"none !important"},attrs:{"color":_vm.iconColor(elemento, aliquota),"size":"18"}},[_vm._v(" "+_vm._s(_vm.iconName(elemento, aliquota))+" ")]):_vm._e()],1)]}}],null,true)})],1)}),(
              _vm.calculaConcentracaoMedia(_vm.ensaioAliquotasSelecionadas, elemento)
            )?_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDecimal")(_vm.calculaConcentracaoMedia(_vm.ensaioAliquotasSelecionadas, elemento),3, 0))+" ")]):_c('td',[_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]),(
              _vm.calculaResultadoMedio(_vm.ensaioAliquotasSelecionadas, elemento)
            )?_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDecimal")(_vm.calculaResultadoMedio(_vm.ensaioAliquotasSelecionadas, elemento),2, 0))+" ")]):_c('td',[_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])])],2)})],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }