var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EnsaiosAliquotasLogResultadosDialogResultado',{attrs:{"ensaioAliquotaLogResultado":_vm.ensaioAliquotaLogResultado,"identificacaoAmostraAliquota":_vm.identificacaoAmostraAliquota},model:{value:(_vm.dialogResultadoIcp),callback:function ($$v) {_vm.dialogResultadoIcp=$$v},expression:"dialogResultadoIcp"}}),_c('v-card',{attrs:{"tile":"","outlined":""}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.logFiltered,"item-class":_vm.itemClassLog,"disable-pagination":"","hide-default-footer":"","mobile-breakpoint":"0","dense":"","show-expand":_vm.showRemovidos,"single-expand":""},scopedSlots:_vm._u([{key:"item.pesoInicialG",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.resultados.pesoInicialG,4, 0),"sufixo":"g"}})]}},{key:"item.pesoFinalG",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.resultados.pesoFinalG,4, 0),"sufixo":"g"}})]}},{key:"item.porcentagemUmidade",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.resultados.porcentagemUmidade)?_c('div',{attrs:{"textClass":_vm.resultadoLogClass(item, index)}},[_vm._v(" "+_vm._s(_vm._f("formatPercent")(item.resultados.porcentagemUmidade,2, 0))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.porcentagemCarbono",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.resultados.porcentagemCarbono)?_c('div',{attrs:{"textClass":_vm.resultadoLogClass(item, index)}},[_vm._v(" "+_vm._s(_vm._f("formatPercent")(item.resultados.porcentagemCarbono,2, 0))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.aprovado",fn:function(ref){
var item = ref.item;
return [(item.resultados.aprovado)?_c('span',{staticClass:"success--text text--darken-3 font-weight-bold"},[_vm._v(" Sim ")]):_c('span',{staticClass:"error--text text--darken-3 text--disabled"},[_vm._v(" Não ")])]}},{key:"item.massaAmostraG",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.resultados.massaAmostraG,4, 0),"sufixo":"g"}})]}},{key:"item.massaPerolaMg",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.resultados.massaPerolaMg,4, 0),"sufixo":"mg"}})]}},{key:"item.diluicaoMl",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":_vm._f("formatDecimal")(item.resultados.diluicaoMl,0, 0),"sufixo":"ml"}})]}},{key:"item.resultadoIcp",fn:function(ref){
var item = ref.item;
return [(item.arquivoId)?_c('v-icon',{attrs:{"color":item.deletedAt ? 'grey lighten-1' : 'primary'},on:{"click":function($event){return _vm.openDialogResultadoIcp(item)}}},[_vm._v(" mdi-flask-round-bottom ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.arquivo",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{class:_vm.resultadoLogArquivoClass(item, index),attrs:{"disabled":!item.arquivoId,"icon":"","href":_vm.pathArquivoLogResultado(item),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file-download")])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{class:_vm.resultadoLogClass(item, index)},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.createdAt))+" ")])]}},{key:"item.responsavel",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{class:_vm.resultadoLogClass(item, index)},[_vm._v(" "+_vm._s(item.usuarioCreatedBy.nomeCompleto)+" ")])]}},{key:"item.observacoes",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('CustomText',{attrs:{"content":item.observacoes,"textClass":_vm.resultadoLogClass(item, index)}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":2}},[_c('v-card',{staticClass:"pa-1",attrs:{"tile":"","flat":""}},[_c('div',{staticClass:"d-flex"},[_c('v-icon',{attrs:{"left":"","small":"","color":"grey"}},[_vm._v(" mdi-subdirectory-arrow-right ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"font-weight-medium mr-2"},[_vm._v("Justificativa")]),_c('div',[_vm._v(_vm._s(item.justificativaDesfazerResultado))])])],1)],1)])],1),_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length > 6 ? 2 : 1}},[_c('v-card',{staticClass:"pa-1",attrs:{"tile":"","flat":""}},[_c('div',{staticClass:"font-weight-medium mr-2"},[_vm._v("Desfeito em")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.deletedAt))+" ")])])],1),(item.usuarioDeletedBy)?_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length > 6 ? 4 : 3}},[_c('v-card',{staticClass:"pa-1",attrs:{"tile":"","flat":""}},[_c('div',{staticClass:"font-weight-medium mr-2"},[_vm._v("Desfeito por")]),_c('div',[_vm._v(" "+_vm._s(item.usuarioDeletedBy.nomeCompleto)+" ")])])],1):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.deletedAt)?_c('v-icon',{class:[
            'v-data-table__expand-icon',
            { 'v-data-table__expand-icon--active': isExpanded } ],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v(" $expand ")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }