var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AmostrasDialogInfo',{attrs:{"amostraId":_vm.amostraId,"infoResumida":"","semAnexos":""},on:{"amostra-removida":_vm.onAmostraRemovida,"amostra-alterada":_vm.onAmostraAlterada},model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}}),(_vm.dialogCadastro)?_c('AmostrasUtingaDialogCadastro',{on:{"amostra-cadastrada":_vm.onAmostraCadastrada},model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-sm-center"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"placeholder":"Busca","append-icon":"mdi-magnify","clearable":"","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mb-2 ml-sm-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogCadastro = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Cadastrar amostras")])],1),_c('v-btn',{staticClass:"mb-2 ml-sm-2",attrs:{"color":"secondary"},on:{"click":function($event){_vm.modoEnvio = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-send")]),_c('span',[_vm._v("Enviar Amostras")])],1)],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.amostrasUtinga,"item-class":_vm.itemClassColor,"headers":_vm.headers,"loading":_vm.loading.amostrasUtinga,"show-select":_vm.modoEnvio,"sort-by":"urgente","sort-desc":"","disable-pagination":"","hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.amostraIdentificacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.prefixo)+" "+_vm._s(item.identificacao)+" ")]}},{key:"item.amostraTipo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("TipoAmostraEnum." + (item.tipo))))+" ")]}},{key:"item.amostraDescricao",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.amostraUtinga.descricao}})]}},{key:"item.amostraPedido",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.amostraUtinga.pedidoSapPedidoCompra}})]}},{key:"item.amostraFornecedor",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.amostraUtinga.pedidoSapFornecedorSapIdentificacao}})]}},{key:"item.pesoMaterialLiquidoKg",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.pesoMaterialLiquidoKg,"sufixo":"kg"}})]}},{key:"item.pesoAmostraKg",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.pesoAmostraKg,"sufixo":"kg"}})]}},{key:"item.solicitante",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.amostraUtinga.pedidoSapSolicitante}})]}},{key:"item.amostraLogAtualCreatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.amostraLogAtualCreatedAt))+" ")]}},{key:"item.usuarioNomeCompleto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.usuarioNomeCompleto)+" ")]}},{key:"item.urgente",fn:function(ref){
var item = ref.item;
return [(item.urgente)?_c('span',{staticClass:"error--text text--darken-4 font-weight-bold"},[_vm._v(" Sim ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("Não")])]}},{key:"item.detalhes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function($event){return _vm.openDialogInfo(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true),model:{value:(_vm.amostrasSelecionadas),callback:function ($$v) {_vm.amostrasSelecionadas=$$v},expression:"amostrasSelecionadas"}})],1),(_vm.modoEnvio)?_c('div',{staticClass:"d-flex justify-space-between mt-3"},[_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.amostrasSelecionadas = [];
        _vm.modoEnvio = false;}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_c('span',[_vm._v("Cancelar")])],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-body-2 text--secondary mr-3"},[_vm._v(" "+_vm._s(_vm.amostrasSelecionadas.length)+" amostra(s) selecionada(s) ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.confirmarEnvio}},[_c('v-icon',[_vm._v("mdi-check")]),_c('span',[_vm._v("Confirmar envio")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }