var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items":_vm.amostras,"headers":_vm.headers,"hide-default-footer":"","disable-pagination":"","disable-sort":"","no-data-text":"Nenhuma amostra associada ao pedido","dense":""},scopedSlots:_vm._u([{key:"item.identificacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.prefixo)+" "+_vm._s(item.identificacao)+" ")]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [(item.amostraPlaca)?[_vm._v(" "+_vm._s(item.amostraPlaca.placaCodigo)+" "+_vm._s(item.amostraPlaca.placaMaterial)+" ")]:_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.pesoMaterialLiquidoKg",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.pesoMaterialLiquidoKg,"sufixo":" kg"}})]}},{key:"item.pesoAmostraKg",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.pesoAmostraKg,"sufixo":" kg"}})]}},{key:"item.urgente",fn:function(ref){
var item = ref.item;
return [(item.urgente)?_c('span',{staticClass:"error--text text--darken-3 font-weight-bold"},[_vm._v(" Sim ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("Não")])]}},{key:"item.etapa",fn:function(ref){
var item = ref.item;
return [_c('AmostrasLogsCustomLabel',{attrs:{"etapa":item.amostraLogAtualEtapa}})]}},{key:"item.observacoes",fn:function(ref){
var item = ref.item;
return [(item.observacoes)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-tooltip-text")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.observacoes)+" ")])]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }