var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AmostrasCatalisadoresDialogRecebimento',{on:{"amostras-recebidas":_vm.onAmostrasRecebidas},model:{value:(_vm.dialogRecebimento),callback:function ($$v) {_vm.dialogRecebimento=$$v},expression:"dialogRecebimento"}}),_c('AmostrasDialogInfo',{attrs:{"amostraId":_vm.amostraId},on:{"amostra-removida":_vm.onAmostraRemovida,"amostra-alterada":_vm.onAmostraAlterada},model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}}),_c('div',{staticClass:"d-flex flex-column-reverse flex-sm-row align-sm-center"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"placeholder":"Busca","append-icon":"mdi-magnify","clearable":"","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.permissaoReceber)?_c('v-btn',{staticClass:"mb-2 ml-sm-2",attrs:{"color":"primary","disabled":_vm.modoColeta},on:{"click":function($event){_vm.dialogRecebimento = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-inbox-arrow-down")]),_c('span',[_vm._v(_vm._s(_vm.$t('comum.receberAmostras')))])],1):_vm._e()],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"items":_vm.amostrasCatalisadores,"item-class":_vm.itemClassColor,"headers":_vm.headers,"show-select":_vm.modoColeta,"loading":_vm.loading.amostrasCatalisadores,"sort-by":"urgente","sort-desc":"","disable-pagination":"","hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.identificacao",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openDialogInfo(item)}}},[_vm._v(" "+_vm._s(item.prefixo)+" "+_vm._s(item.identificacao)+" ")])]}},{key:"item.amostraLogAtualCreatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.amostraLogAtualCreatedAt))+" ")]}},{key:"item.amostraCatalisador.silo",fn:function(ref){
var item = ref.item;
return [(item.amostraCatalisador.silo)?_c('span',[_vm._v(" "+_vm._s(item.amostraCatalisador.silo)+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.urgente",fn:function(ref){
var item = ref.item;
return [(item.urgente)?_c('span',{staticClass:"error--text text--darken-4 font-weight-bold"},[_vm._v(" Sim ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("Não")])]}},{key:"item.detalhes",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"info","disabled":!_vm.permissaoBuscar},on:{"click":function($event){return _vm.openDialogInfo(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,true),model:{value:(_vm.amostrasSelecionadas),callback:function ($$v) {_vm.amostrasSelecionadas=$$v},expression:"amostrasSelecionadas"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }