var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"show-select":_vm.criaAmostra,"items":_vm.bags,"headers":_vm.headers,"item-class":_vm.itemClassBag,"hide-default-footer":"","disable-pagination":"","no-data-text":"Nenhum bag cadastrado","dense":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [(item.amostra)?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-package-variant-closed-check ")]):_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.fotoArquivo",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"mb-2 mt-2",attrs:{"outlined":""}},[_c('a',{style:(!item.fotoArquivoId ? 'pointer-events:none;' : ''),attrs:{"href":_vm.pathFotoArquivo(item),"target":"_blank"}},[_c('v-img',{attrs:{"src":_vm.pathFotoArquivo(item),"width":60,"height":40}},[(!item.fotoArquivoId)?_c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"d-flex flex-column justify-center align-center fill-height grey lighten-5",staticStyle:{"border":"1pt dashed #ccc"}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-image-off")])],1)]):_vm._e()])],1)])]}},{key:"item.descricaoPlaca",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.descricaoMaterial(item))+" ")]}},{key:"item.pesoLiquidoKg",fn:function(ref){
var item = ref.item;
return [_c('CustomText',{attrs:{"content":item.pesoLiquidoKg,"sufixo":" kg"}})]}},{key:"item.observacoes",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"pre-wrap","max-width":"500px"}},[_c('CustomText',{attrs:{"content":item.observacoes}})],1)]}},{key:"item.amostraBag",fn:function(ref){
var item = ref.item;
return [(_vm.amostraIdentificacao(item.amostra))?_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.amostraIdentificacao(item.amostra))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.remover",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.removeBag(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.bagsSelecionados),callback:function ($$v) {_vm.bagsSelecionados=$$v},expression:"bagsSelecionados"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }